<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="showAddScoreDialog"
    width="965px"
    :close-on-click-modal="false"
    @close="handelClose"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="评分机构" prop="projectDepartment" :required="!isRead && dialogTitle !== '修改评分表'">
            <el-select
              v-model="ruleForm.projectDepartment"
              placeholder="请选择评分机构"
              style="width:350px"
              :disabled="isRead || dialogTitle === '修改评分表'"
              @change="hendelChangeProject"
            >
              <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="评分日期" prop="ratingDate" :required="!isRead && dialogTitle !== '修改评分表'">
            <el-date-picker
              :disabled="isRead"
              style="width:350px"
              value-format="yyyy-MM-dd"
              v-model="ruleForm.ratingDate"
              type="date"
              placeholder="请选择评分日期"
              :clearable="false"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 表格填写 -->
    <div v-if="isTabelShow">
      <div v-for="(item, index) in tableSingleList" :key="index">
        <p class="tabel-title">{{ item.tableName }}</p>
        <el-table
          :data="item.tableContent.length ? item.tableContent : []"
          style="width: 100%"
          :cell-style="{ background: '#fff' }"
          size="medium"
          border
          empty-text="当前评分表暂无检查项目，请联系管理员配置检查项目"
          max-height="400px"
          v-loading="dialogLoading"
        >
          <el-table-column label="序号" width="55" fixed align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="checkItem" label="检查项目" fixed align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deductionStandard" label="扣分标准" align="center">
            <template slot-scope="{ row }">
              <p style="white-space:pre-wrap">{{ row.deductionStandard }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="score" label="应得分数" align="center" width="120"> </el-table-column>
          <el-table-column prop="realScore" label="实得分数" align="center" width="120">
            <template slot-scope="{ row }">
              <!-- v-only-number="{max:100,min:0,precision:2}" 暂时不用自定义指令，分页输入的话会有bug-->
              <!--  <el-input
                v-model.number="row.realScore"
                placeholder="点击输入"
                :disabled="isRead"
                oninput="value = value.replace(/(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/, '$1')"
              ></el-input> -->
              <el-input-number
                v-model="row.realScore"
                :controls="false"
                :min="0"
                :disabled="isRead"
                :max="row.score"
                :precision="2"
                style="width:80px"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        :page-size="1"
        :current-page="pageNo + 1"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next"
      ></el-pagination>
    </div>

    <div slot="footer" v-if="$check('construction-inspection_edit')">
      <div v-if="!isRead">
        <el-button @click="resetForm">取消</el-button>
        <el-button @click="submitForm" type="primary">确定</el-button>
      </div>
      <div v-else>
        <el-button type="danger" @click="handelDelete">删除</el-button>
        <el-button type="primary" @click="handelUpdate">修改</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  addScope,
  getHazardDetail,
  deleteTable,
  editTabelScope
} from 'api/general-user/prevention-control/construction-inspection';
export default {
  name: 'AddScoreDialog',
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    isRead: {
      //是否可读(查看详情为true)
      type: Boolean,
      required: true,
      default: false
    },
    projectList: {
      type: Array,
      required: true,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dialogLoading: false,
      ruleForm: {},
      showAddScoreDialog: this.dialogVisible,
      rules: {
        projectDepartment: [{ required: this.isRead, message: '请选择评分机构' }],
        ratingDate: [{ required: this.isRead, message: '请选择评分日期' }]
      },
      //新增填写的表格参数
      tabelParmers: {
        recordId: '',
        pageNo: 1,
        pageSize: 1
      },
      ruleForm: {},
      total: 0,
      tableList: [],
      pageNo: 0, //当前点击的是几页,
      projectDepartmentId: '' //dialog记录下拉选择所属机构的id,
    };
  },
  methods: {
    initFormDetail(recordId) {
      this.tabelParmers.recordId = recordId;
    },
    hendelChangeProject(id) {
      this.projectDepartmentId = id;
    },
    submitForm() {
      //确定
      this.$refs.ruleForm.validate(valid => {
        if (valid && this.isTabelFillDataAllFunc()) {
          if (this.tabelParmers.recordId) {
            this.editTabelScope(); //编辑接口
          } else {
            this.addScope(); //新增接口
          }
        }
      });
    },

    //判断输入是否完整
    isTabelFillDataAllFunc() {
      let flag = true;
      this.tableList.forEach(item => {
        if (item.tableContent.length) {
          //这里可能item.tableContent为空数组
          item.tableContent.find(singel => {
            if (!singel.realScore) {
              flag = false;
            }
          });
        }
      });
      if (!flag) {
        this.$message.warning('未完成所有项评分，请完成后保存');
      }
      return flag;
    },
    resetForm() {
      this.dialogCloseCommomFunc();
    },
    handelClose() {
      this.dialogCloseCommomFunc();
    },
    dialogCloseCommomFunc() {
      this.$emit('update:dialogVisible', false);
      this.$emit('update:isRead', false);
      this.tableList = [];
      this.tabelParmers.recordId = ''; //这里很重要
      this.ruleForm = {};
      this.projectDepartmentId = '';
      this.pageNo = 0;
      this.$refs.ruleForm.resetFields();
    },
    handleCurrentChange(current) {
      this.dialogLoading = true;
      this.pageNo = current - 1;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    handelDelete() {
      this.$confirm('请确认是否删除该评分表?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteTable();
      });
    },
    //查看详情或者新增时获取表接口
    getHazardDetail() {
      this.dialogLoading = true;
      getHazardDetail(this.tabelParmers)
        .then(res => {
          if (res.code === 200) {
            const { tableList, projectDepartment, ratingDate } = res.data;
            if (tableList.length) {
              this.pageNo = 0;
              this.dialogLoading = false;
              this.tableList = tableList;
              //点击新增的时候才处理(为了处理input-number默认值显示为0的情况)
              if (!this.tabelParmers.recordId) {
                this.tableList.map(item => {
                  if (item.tableContent.length) {
                    //这里可能item.tableContent为空数组
                    item.tableContent.map(singel => {
                      singel.realScore = undefined;
                    });
                  }
                });
              }
              this.total = tableList.length;
              if (this.tabelParmers.recordId) {
                let obj = {
                  projectDepartment: projectDepartment,
                  ratingDate: ratingDate
                };
                this.ruleForm = obj;
              }
            }
          }
        })
        .catch(() => {
          this.dialogLoading = false;
        });
    },
    //修改
    handelUpdate() {
      this.dialogLoading = true;
      this.pageNo = 0;
      this.$emit('update:dialogTitle', '修改评分表');
      this.$emit('update:isRead', false);
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    //删除接口
    deleteTable() {
      deleteTable({ recordId: this.tabelParmers.recordId }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      });
    },
    //编辑接口
    editTabelScope() {
      let params = this.ruleForm;
      params.recordId = this.tabelParmers.recordId;
      let editContent = [];
      this.tableList.forEach(item => {
        if (item.tableContent.length) {
          item.tableContent.forEach(single => {
            editContent.push({ id: single.id, realScore: single.realScore });
          });
        }
      });
      params.editContent = editContent;
      editTabelScope(params).then(res => {
        if (res.code === 200) {
          this.$message.success('修改成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      });
    },
    //新增接口
    addScope() {
      addScope(Object.assign(this.ruleForm, { tableList: this.tableList })).then(res => {
        if (res.code === 200) {
          this.$message.success('新增成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', true);
        }
      });
    }
  },
  computed: {
    tableSingleList() {
      //dialog每页的Item
      let singleTabel = [];
      if (this.tableList.length) {
        singleTabel.push(
          this.tableList.find((item, index) => {
            if (index == this.pageNo) {
              return item;
            }
          })
        );
      }
      return singleTabel;
    },
    isTabelShow() {
      //是否显示dialog表格
      return this.ruleForm.projectDepartment && this.ruleForm.ratingDate;
    }
  },
  watch: {
    dialogVisible(val) {
      this.showAddScoreDialog = val;
      if (val) {
        this.getHazardDetail();
      }
    },
    'ruleForm.projectDepartment': {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal && oldVal && newVal && this.projectDepartmentId !== oldVal) {
          this.$confirm('切换项目部后数据将清空，请确认是否继续？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            type: 'warning'
          })
            .then(() => {
              this.getHazardDetail();
            })
            .catch(() => {
              this.ruleForm.projectDepartment = oldVal;
            });
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.tabel-title {
  font-weight: 700;
  font-size: 16px;
  color: #303133;
  text-align: center;
}
</style>
