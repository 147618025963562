import request from '@/utils/request.js';
/**
 *分页列表
 * @returns {Promise}
 */
export function getHazardPage(data) {
  return request({
    url: '/traffic-construction/getHazardPage',
    method: 'post',
    data
  });
}

/**
 *新增
 * @returns {Promise}
 */
export function addScope(data) {
  return request({
    url: '/traffic-construction/addScope',
    method: 'post',
    data
  });
}

/**
 *查看详情或者新增时获取表
 * @returns {Promise}
 */
export function getHazardDetail(params) {
  return request({
    url: '/traffic-construction/getHazardDetail',
    method: 'get',
    params
  });
}

/**
 *删除
 * @returns {Promise}
 */
export function deleteTable(data) {
  return request({
    url: '/traffic-construction/deleteTable',
    method: 'post',
    data
  });
}

/**
 *修改
 * @returns {Promise}
 */
export function editTabelScope(data) {
  return request({
    url: '/traffic-construction/editScope',
    method: 'post',
    data
  });
}

/**
 *获取施工安全检查图表数据
 * @returns {Promise}
 */
export function getHazardPhoto(data) {
  return request({
    url: '/traffic-construction/getHazardPhoto',
    method: 'post',
    data
  });
}

/**
 *获取施工安全检查图表数据
 * @returns {Promise}
 */
export function exportScDetail(data) {
  return request({
    url: '/traffic-construction/exportScDetail',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
