<template>
  <div>
    <!-- 列表搜索 -->
    <div class="search-list">
      <el-form inline :model="queryForm">
        <el-form-item label="所属项目部">
          <el-select v-model="queryForm.ids" placeholder="请选择所属项目部" multiple collapse-tags style="width:320px">
            <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handelSearch">查询</el-button>
          <el-button @click="handelReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 操作按钮 -->
    <operate-button>
      <template #default>
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="add-btn"
          @click="handelAddScore"
          v-if="$check('construction-inspection_add')"
          >新增评分记录</el-button
        >
        <el-button
          v-if="$check('construction-inspection_export')"
          class="export-btn"
          @click="handleExport"
          :disabled="btnDisabeld"
          v-loading.fullscreen="exportLoading"
          element-loading-text="拼命导出中"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          >导出</el-button
        >
      </template>
    </operate-button>
    <!-- 列表 -->
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="tabelLoading"
        :header-row-style="{ color: '#303133' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed align="center"> </el-table-column>
        <el-table-column label="序号" width="55" fixed align="center" type="index"> </el-table-column>
        <el-table-column prop="name" label="项目部名称" fixed align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column
          prop="total_score"
          label="总计得分"
          align="center"
          width="150"
          sortable
          :render-header="renderHeaderAllScore"
        >
        </el-table-column>
        <el-table-column label="各项分值" align="center">
          <el-table-column
            :prop="item.prop"
            v-for="(item, index) in tabelHeadData"
            :key="item.prop"
            :render-header="renderHeader"
            :label="`${item.name}` + '换行' + `${item.points}`"
            align="center"
            sortable
          >
            <template slot-scope="{ row, $index }">
              <span>{{ row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="rating_date" label="评分日期" align="center" width="120" sortable> </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="120">
          <template slot-scope="{ row }">
            <span class="action-btn" @click="handelDetail(row.record_id)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- 新增评分记录 -->
    <add-score-dialog
      ref="addScoreRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :is-read.sync="isRead"
      :project-list="projectList"
      @handel-success="handelSuccess"
    ></add-score-dialog>
  </div>
</template>

<script>
import { exportFun } from '@/utils/export.js';
import operateButton from '../../_components/operateButton';
import { getProjectDepartmentList } from 'api/common';
import { getHazardPage, exportScDetail } from 'api/general-user/prevention-control/construction-inspection';
import addScoreDialog from './addScoreDialog';
export default {
  components: {
    operateButton,
    addScoreDialog
  },
  data() {
    return {
      queryForm: {
        ids: [],
        pageNo: 1,
        pageSize: 10
      },
      tabelLoading: false,
      projectList: [],
      tableData: [], //列表数据
      tabelHeadData: [], //表头
      total: 0,
      allScope: 0,
      //新增数据
      dialogTitle: '',
      dialogVisible: false,
      isRead: false,
      //导出需要的ids
      ids: [],
      exportLoading: false,
      btnDisabeld: true
    };
  },
  methods: {
    handleSelectionChange(row) {
      this.ids = [];
      row.forEach(item => {
        this.ids.push(item.id);
      });
      this.btnDisabeld = !Boolean(this.ids.length);
    },
    //导出
    handleExport() {
      this.exportLoading = true;
      exportScDetail({
        ids: this.ids
      })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '导出数据成功',
              onClose: () => {
                this.$refs.multipleTable.clearSelection();
              }
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
          this.$refs.multipleTable.clearSelection();
        });
    },
    renderHeader(h, { column, $index }) {
      return h(
        //循环表头换行处理
        'div',
        {
          domProps: {
            innerHTML:
              column.label.substr(0, column.label.indexOf('换行')) +
              '</br>' +
              '（' +
              column.label.substr(column.label.indexOf('换行') + 2, column.label.length - 1) +
              '分)'
          }
        },
        [h('span')]
      );
    },
    renderHeaderAllScore(h, { column, $index }) {
      return h(
        //总计得分换行处理
        'div',
        {
          domProps: {
            innerHTML: column.label + '</br>' + '（' + this.allScope + '分）'
          }
        },
        [h('span')]
      );
    },
    handelSuccess(boolean) {
      //true->新增，false编辑
      //刷新列表
      if (boolean) {
        this.queryForm.pageNo = 1;
      }
      this.getHazardPage();
    },
    handelAddScore() {
      this.dialogVisible = true;
      this.dialogTitle = '新增评分记录';
    },
    handelDetail(recordId) {
      this.isRead = true;
      this.dialogVisible = true;
      this.dialogTitle = '查看详情';
      this.$refs.addScoreRef.initFormDetail(recordId);
    },
    handelSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getHazardPage();
    },
    handelReset() {
      this.queryForm = {};
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.$refs.multipleTable.clearSort();
      this.getHazardPage();
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getHazardPage();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getHazardPage();
    },
    getHazardPage() {
      //分页数据
      this.tabelLoading = true;
      getHazardPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tabelLoading = false;
          const { page, pageHeader, allScope } = res.data;
          this.total = page.total;
          this.allScope = allScope;
          this.tabelHeadData = pageHeader;
          this.tableData = page.records;
        }
      });
    },
    getProjectDepartmentList() {
      //这个单独接口获取(所属项目部)
      getProjectDepartmentList().then(res => {
        if (res.code === 200) {
          let { data } = res;
          this.projectList = data;
        }
      });
    }
  },
  created() {
    this.getProjectDepartmentList();
    this.getHazardPage();
  }
};
</script>
