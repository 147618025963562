<template>
  <div class="echart-display">
    <!-- 搜素部分 -->
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="所属项目部">
          <el-select
            v-model="queryForm.ids"
            placeholder="请选择项目部"
            multiple
            collapse-tags
            style="width:320px"
            @change="changeProjectList"
          >
            <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其它筛选条件">
          <el-select
            v-model="queryForm.checkIds"
            placeholder="请选择各项分值"
            collapse-tags
            multiple
            
            @change="changeCheckList"
          >
            <el-option v-for="item in checkList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 图表 -->
    <div>
      <echarts-line ref="echartRef" :x-data="xData" :data-list="dataList"></echarts-line>
    </div>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import { cloneDeep } from 'lodash';
import { getProjectDepartmentList } from 'api/common';
import { getHazardPhoto } from 'api/general-user/prevention-control/construction-inspection';
import { getSafetyCheckList } from 'api/super-admin/sys-setting/score-setting.js';
import echartsLine from 'components/EchartsLine';
export default {
  components: {
    echartsLine
  },
  data() {
    return {
      queryForm: {
        ids: [],
        riskLevelIds: [],
        checkIds: []
      },
      checkListParams: {
        tenantId: getItem('tenantInfo').tenantId
      },
      projectList: [
        {
          name: '全部',
          id: ''
        }
      ],
      checkList: [
        {
          nickname: '全部',
          id: ''
        }
      ],
      xData: [], //图表横坐标
      dataList: [], //图表数据
      isCheckedAllProjectFlag: false, //所属项目部(是否全选)
      isCheckedAllSoreFlag: false //各项分值(是否全选)
    };
  },
  methods: {
    changeProjectList(id) {
      //选择属项目部
      if (!this.isCheckedAllProjectFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.projectList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.ids = arr;
          this.isCheckedAllProjectFlag = true;
        } else {
          this.queryForm.ids = id;
        }
      } else {
        this.isCheckedAllProjectFlag = false;
        this.queryForm.ids = id.filter(item => item !== '');
      }
      this.getHazardPhoto();
    },
    changeCheckList(id) {
      //选择各项目分值
      this.queryForm.riskLevelIds = [];
      if (!this.isCheckedAllSoreFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.checkList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.checkIds = arr;
          this.isCheckedAllSoreFlag = true;
        } else {
          this.queryForm.checkIds = id;
        }
      } else {
        this.isCheckedAllSoreFlag = false;
        this.queryForm.checkIds = id.filter(item => item !== '');
      }
      this.getHazardPhoto();
    },
    getHazardPhoto() {
      //项目部危险源数量总数（默认）简单柱状图
      let params = cloneDeep(this.queryForm);
      if (params.ids.includes('')) {
        //判断数组中是否有空字符串
        params.ids = []; //传[]或者所有id集合或者不传字段三种都可以（只有项目部才有，其他都传id集合）
      }
      if (params.checkIds.includes('')) {
        params.checkIds = params.checkIds.filter(item => item !== '');
      }
      getHazardPhoto(params).then(res => {
        if (res.code === 200) {
          const { scDetailPhotoDetail, name, score } = res.data;
          this.xData = name; //横坐标的值
          if (res.code === 200) {
            if (scDetailPhotoDetail.length) {
              //只要选择了其他下拉选择，scDetailPhotoDetail字段就有值
              scDetailPhotoDetail.forEach(item => {
                item.type = 'bar';
                item.stack = 'bar';
                item.barWidth = 20;
                item.data = item.score;
              });
              this.dataList = scDetailPhotoDetail;
            } else {
              //只选择了所属项目部
              this.dataList = [
                {
                  data: score,
                  type: 'bar',
                  barWidth: 20,
                  backgroundColor: '#1890FF',
                  name: '总计得分'
                }
              ];
            }
          }
          this.$nextTick(() => {
            this.$refs.echartRef.initEchartsLine(); //调用子组件echarts里面的配置方法
          });
        }
      });
    },
    getProjectDepartmentList() {
      //这个单独接口获取(所属项目部)
      getProjectDepartmentList().then(res => {
        if (res.code === 200) {
          let { data } = res;
          this.projectList = [...this.projectList, ...data];
        }
      });
    },
    getSafetyCheckList() {
      //列表
      getSafetyCheckList(this.checkListParams).then(res => {
        if (res.code === 200) {
          this.checkList = [...this.checkList, ...res.data.safetyCheckList];
        }
      });
    }
  },
  created() {
    this.getSafetyCheckList();
    this.getHazardPhoto();
    this.getProjectDepartmentList();
  }
};
</script>

<style scoped lang="less"></style>
